import React from 'react';
import image1 from '../images/hackathon_118.jpeg'
import image2 from '../images/hackathon_139.jpeg'
import image3 from '../images/hackathon_141.jpeg'
import image4 from '../images/hackathon_164 Large.jpeg'
import image5 from '../images/hackathon_165 Large.jpeg'
import sloganBackground from '../images/comppani_slogan.png'
import mainos1 from '../images/Mainos1 Large.png'
import mainos2 from '../images/mainos2 Large.png'

function Home() {
  return (
<div class="homeContent">
    <div class="slogan-section">
      <img class="slogan-img" src={sloganBackground} alt="image3" />
      <div class="slogan-text">
        <h1>Here at</h1>
        <h2>Aestus Systems</h2>
        <h1>we believe in</h1>
        <h1>a safer</h1>
        <h1>future.</h1>
      </div>
    </div>

    <div class="homePage">
      <div class="infoContainer">
        <p>Gathering real time data from hostile environments is hard. Accurate and resilient sensor networks are currently costly and slow to set up. In addition, current sensor networks cannot detect a wide range of information and make conclusions based on the data from one node.</p>
        <img src={image5} alt="image5" />
      </div>

      <div class="infoContainer">
        <img src={image2} alt="image2" />
        <p>This is where Aestus Systems comes in. We are developing a dual use resilient sensor mesh for threat detection and people flow analysis. Our mesh is the next generation of battlefield surveillance and gives real-time data straight from the field. With rapid deployment and ad hoc capabilities our sensor mesh is easy to use and set up.</p>
      </div>

      <div class="infoContainer">
        <p>Aestus Systems' sensor mesh can be used in various ways. The most distinct being reconnaissance use in defence sector. In addition, it can be used in civilian applications, such as monitoring critical infrastructure or tracking people flow in cities.</p>
        <img src={image4} alt="image4" />
      </div>

      <div class="infoContainer">
        <img src={mainos2} alt="mainos2" />
        <p>We are currently developing the technology and working hard to bring this vision into reality. Please contact us if you are interested in attending our pilot project or collaborating with us.</p>
      </div>

    </div>
</div>
  );
}

export default Home;