import React, { useState } from 'react';
import { CFooter, CLink } from '@coreui/react'


function Footer() {
    return (
        <CFooter>
  <div>
    <span>&copy; 2024 Aestus Systems</span>
  </div>
  <div>
    <span>Contact us: </span>
    <a href="https://forms.gle/HheA6YaALcppyauEA">Here!</a>
  </div>
  <div>
    <span>Powered by CoreUI</span>
  </div>
</CFooter>
    );
}

export default Footer