import React from 'react';
import '../styles/meetTheTeam.css'; // External CSS for styling
import placeholder from '../images/User_Avatar_2.png'

const About = () => {
  const teamMembers = [
    { name: 'Annika Eloranta', image: placeholder, description: 'Computer Science & Automation Software at Aalto University, web developer' },
    { name: 'Kristian Kholodov', image: placeholder, description: 'Automation and Robotics Engineering student at Aalto University, specialising in embedded systems development' },
    { name: 'Aarno Koponen', image: placeholder, description: 'Electronics and Electrical engineering student at Aalto University, R&D' },
    { name: 'Miikka Leppinen', image: placeholder, description: 'Automation and robotics student at Aalto University.' },
    { name: 'Emil Lintunen', image: placeholder, description: 'Maker, hacker, student of Aalto, teacher and R&D engineer' },
    { name: 'Rika Lintunen', image: placeholder, description: 'Automation & robotics student at Aalto, electronics designer, embedded programmer and maker.' },
    { name: 'Pinja Mäkeläinen', image: placeholder, description: 'Electronic and Digital Systems student at Aalto University' },
  ];

  return (
    <div className="about-container">
      {teamMembers.map((member, index) => (
        <div key={index} className="team-member">
          <img src={member.image} alt={member.name} className="team-image" />
          <div className="member-info">
            <h3>{member.name}</h3>
            <h4>{member.description}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};

export default About;