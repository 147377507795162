import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.css'; // Ensure correct CSS path
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') // Make sure the element ID matches the HTML
);